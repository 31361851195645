import "./App.css";
import "./Customer.css";
import Home from "./pages/home/Home";
import jQuery from "jquery";
import React, {useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Settings from "./pages/my_account/Settings";
import ProSettings from "./pages/my_account/ProSettings";
import Help from "./pages/my_account/Help";
import HelpSubQuestions from "./pages/my_account/HelpSubQuestions";
import SeeAllHistory from "./pages/my_account/SeeAllHistory";
import HelpDetail from "./pages/my_account/HelpDetail";
import CreateSupportTicket from "./pages/my_account/CreateSupportTicket";
import EditPersonalDetails from "./pages/my_account/EditPersonalDetails";
import Address from "./pages/my_account/Address/Address";
import CreditCard from "./pages/my_account/CreditCard";
import AddCreditCard from "./pages/my_account/AddCreditCard";
import EditCreditCard from "./pages/my_account/EditCreditCard";
import Appointments from "./pages/my_account/Appointments";
import AppointmentDetails from "./pages/my_account/AppointmentDetails";
import RescheduleAppointment from "./pages/my_account/RescheduleAppointment";
import CancelMyAppointment from "./pages/my_account/CancelMyAppointment";
import CancelAppointment from "./pages/my_account/CancelAppointment";
import BookingCancel from "./pages/my_account/BookingCancel";
import WriteReview from "./pages/my_account/WriteReview";
import Review from "./pages/my_account/Review";
import RighthandCredit from "./pages/my_account/RighthandCredit";
import CreditPackages from "./pages/my_account/CreditPackages";
import CreditPackagesPayment from "./pages/my_account/CreditPackagesPayment";
import FreeCredit from "./pages/my_account/FreeCredit";
import CreditDetails from "./pages/my_account/CreditDetails";
import ServiceCheckout from "./pages/appointment_booking/service/ServiceCheckout";
import ProfessionalProfile from "./pages/appointment_booking/service/components/ProfessionalProfile";
import FrequencyCheckout from "./pages/appointment_booking/frequency/FrequencyCheckout";
import FAQ from "./components/FAQ";
import TermsConditions from "./components/TermsConditions";
import RHClubPolicy from "./components/RHClubPolicy";
import RHClubPolicyDetails from "./components/RHClubPolicyDetails";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ServiceDetails from "./components/ServiceDetails";
import Cancellation from "./components/Cancellation";
import ReschedulePolicy from "./components/ReschedulePolicy";
import BlogDetail from "./components/BlogDetail";
import BlogsList from "./components/BlogsList";
import RescheduleAppointmentPolicy from "./pages/my_account/RescheduleAppointmentPolicy";
import Covid from "./components/Covid";
import Success from "./components/Success";
import EULA from "./components/EULA";
import Disclaimer from "./components/Disclaimer";
import CookiesPolicy from "./components/CookiesPolicy";
import Deeplink from "./components/Deeplink";
import CommonCheckout from "./pages/my_account/CommonCheckout";
import About from "./pages/About";
import RighthandGuarntee from "./pages/RighthandGuarntee";
import Membership from "./pages/Membership";
import BuyMemberShip from "./pages/my_account/BuyMemberShip";
import Login from "./pages/customer/Login";
import AccountDetails from "./pages/my_account/AccountDetails";
import ProfileUpdate from "./pages/my_account/ProfileUpdate";
import ChangePassword from "./pages/my_account/ChangePassword";
import ResetPassword from "./pages/my_account/ResetPassword";
import MyfavPros from "./pages/my_account/MyfavPros";
import ForgotPassword from "./pages/customer/ForgotPassword";
import ClaimAccount from "./pages/customer/ClaimAccount";
import SignwithMobile from "./pages/customer/SignwithMobile";
import BlankLoading from "./components/BlankLoading";
import CardUpdating from "./pages/appointment_booking/frequency/components/BlankLoading";
import {Otp} from "react-otp-timer";
import Disinfection from "./pages/appointment_booking/service/servicestep/ServicePage";
import Popups from "./components/Popups";
import TabsServicePage from "./pages/customer/services/TabsServicePage";
import ServiceByCategorie from "./pages/customer/services/ServiceByCategorie";
import FreeServices from "./pages/customer/free-service/FreeServices";
import HowItworks from "./pages/customer/free-service/HowItworks";
import TrackInvites from "./pages/customer/free-service/TrackInvites";
import Announcements from "./pages/customer/announcements";
import RighthandsClub from "./pages/customer/righthandsClub";
import ExtendAppointments from "./pages/my_account/ExtendAppointments";
import ExtendedMembership from "./pages/my_account/ExtendedPay";
import AppointmentTrack from "./pages/appointment_booking/AppointmentTrack";
// import LocationSearch from "./components/LocationSearch";
import Zendesk, { ZendeskAPI } from "./ZendexConfig";
// import SuccessFullPayment from "./pages/appointment_booking/frequency/components/SuccessFullPayment";
import ComingSoon from "./components/ComingSoon";

function App() {
  if (!localStorage.getItem("currentLanguage")) {
    localStorage.setItem("currentLanguage", "en");
  }

  const ZENDESK_KEY = "16eb9093-5e86-4a77-8846-17ffcaa93e3c";
  const handleLoaded = () => {
    ZendeskAPI("messenger", "close");
  };

  const currentLanguageCode = localStorage.getItem("currentLanguage");
  const {t} = useTranslation();
  useEffect(() => {
    jQuery("body").attr("dir", currentLanguageCode === "ar" ? "rtl" : "ltr");
    jQuery("title").html(t("app_title"));
    var jqueryHtml = jQuery("html");
    if (currentLanguageCode === "ar") {
      jqueryHtml.addClass("translated-rtl");
    } else {
      jqueryHtml.removeClass("translated-rtl");
    }
    jqueryHtml.attr("lang", currentLanguageCode);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        let api = "";
        //api = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_LOCAL_API_URL}`;
        api = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
        fetch(api)
          .then((response) => response.json())
          .then((data) =>
            localStorage.setItem("currentCountryCode", data.countryCode)
          );
      });
    } else {
      localStorage.setItem("currentCountryCode", "AE");
      console.log("Your browser doesn't support geolocation.");
    }
  }, [currentLanguageCode, t]);

  jQuery(document).on("click", ".flag-dropdown", function () {
    jQuery(".search-class-box").attr("placeholder", t("search"));
  });

  return (
    <>
      <Routes>


      <Route path="*" element={<ComingSoon />} />
       
      </Routes>
      <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
    </>
  );
}

export default App;
