import * as React from 'react'
import { Link } from "react-router-dom";

function ComingSoon() {
    return (

        <div className='errorPage'>
            <div className='pageNotFound'>
            <div className='errorImage' style={{ marginTop: '3%' }}>
                <img
                    src="/images/coming-soon.jpg" width="500"
                    className=" mx-auto"
                    alt="404"
                />
            </div>
            <div className='errorText'>
                <h2 className=" mb-3 	">
                {/* Coming Soon...  */}
                </h2>
                <ul>
                    <li>We're working hard to bring something amazing! 🚀</li>
                    <li>We sincerely apologize for any inconvenience this may cause. Your understanding and patience mean the world to us.</li>
                    <li>If you have any questions or need assistance, feel free to <a href="https://righthandssupport.zendesk.com/hc/en-us" style={{color: 'yellow'}}>contact us</a>. 
                    </li>
                </ul>
            </div>
        </div>
        </div>


    );
}

export default ComingSoon

